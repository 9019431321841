import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const AuthGuard = ({ children }) => {
    const checkCookieExistsByName = (n) => {
        const result = /token=(.[^;]*)/ig.exec(document.cookie)

        if (!result) {
            localStorage.clear()
            return false
        }

        if (!result[1]) {
            localStorage.clear()
            return false
        }

        return true
    }

    return checkCookieExistsByName('token') ? children : <Navigate to="/login" />;
}

export default AuthGuard