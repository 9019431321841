import React, { useState } from "react";
import InputText from "../InputText/InputText";
import api from "../../axios/config";
import Loading from "../Loading/Loading";

const CreatedUserADM = ({ isOpen, onClose, onConfirm }) => {
  const tokenAuthenticated = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [objectUser, setObjectUser] = useState({
    name: "",
    email: "",
    password: "",
    cpf_cnpj: "",
    master: false,
  });

  const postAPICreateUserADM = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        "admin/add",

        {
          name: objectUser.name,
          email: objectUser.email,
          password: objectUser.password,
          cpf_cnpj: objectUser.cpf_cnpj,
          master: false,
        },
        {
          headers: {
            token: tokenAuthenticated,
          },
        },
      );
      const data = response.data;
    } catch (error) {
      console.log(error);
    } finally {
      setObjectUser({
        name: "",
        email: "",
        password: "",
        cpf_cnpj: "",
      });
      setLoading(false);
      onConfirm();
    }
  };

  if (!isOpen) return null;

  return (
    <div className='fixed inset-0 flex items-center justify-center z-50'>
      <div className='fixed inset-0 bg-black opacity-50'></div>
      <div className='bg-white p-6 rounded-lg shadow-lg z-10 max-w-sm w-full'>
        <h2 className='text-xl font-bold mb-4'>Adicionar Administrador:</h2>
        <div className='flex flex-col justify-start items-start mt-1 mb-3  gap-1'>
          <InputText
            label={"Nome do usuario:"}
            value={objectUser.name}
            onChange={(e) =>
              setObjectUser({ ...objectUser, name: e.target.value })
            }
            placeholder={"Nome"}
          />
          <InputText
            label={"Email do usuario:"}
            value={objectUser.email}
            onChange={(e) =>
              setObjectUser({ ...objectUser, email: e.target.value })
            }
            placeholder={"Email"}
          />
          <InputText
            label={"Senha do usuario:"}
            value={objectUser.password}
            onChange={(e) =>
              setObjectUser({ ...objectUser, password: e.target.value })
            }
            placeholder={"Senha"}
          />
          <InputText
            label={"CPF / CNPJ do usuario:"}
            value={objectUser.cpf_cnpj}
            onChange={(e) =>
              setObjectUser({ ...objectUser, cpf_cnpj: e.target.value })
            }
            placeholder={"CPF / CNPJ"}
          />
        </div>
        <div className='flex justify-end space-x-4'>
          <button
            className='w-50 bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 focus:outline-none focus:bg-red-600 ml-4'
            onClick={() => {
              setObjectUser({
                name: "",
                email: "",
                password: "",
                cpf_cnpj: "",
              });
              onClose();
            }}
          >
            Cancelar
          </button>
          {loading && <Loading />}
          {!loading && (
            <button
              disabled={
                objectUser.name === "" ||
                objectUser.email === "" ||
                objectUser.password === ""
              }
              className={`w-50 py-2 px-4 rounded-lg text-white ml-4 ${
                objectUser.name === "" ||
                objectUser.email === "" ||
                objectUser.password === ""
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-green-500 hover:bg-green-600 focus:outline-none focus:bg-green-600"
              }`}
              onClick={() => {
                postAPICreateUserADM();
              }}
            >
              Criar Administrador
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreatedUserADM;
