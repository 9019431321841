import React, { useState } from "react";
import Input from "../Input/Input";
import api from "../../axios/config";

const Modal = ({ isOpen, onClose, setValueConstant }) => {
  const tokenAuthenticated = sessionStorage.getItem("token");
  const [diameter, setDiameter] = useState("");
  const [relation, setRelation] = useState("");
  const [angle, setAngle] = useState("");
  const [teeth, setTeeth] = useState("");
  if (!isOpen) return null;

  const cleanInputs = () => {
    setValueConstant("");
    setDiameter("");
    setRelation("");
    setAngle("");
    setTeeth("");
  };

  const postValuesConstant = async () => {
    try {
      const response = await api.post(
        "engine/calc_const_renania/diff",
        {
          diameter: diameter,
          relation: relation,
          angle: angle,
          teeth: teeth,
        },
        {
          headers: {
            token: tokenAuthenticated,
          },
        }
      );
      const data = response.data;
      setValueConstant(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="modal-container fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className=" bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
        <h2 className="text-lg mb-4">
          Cálculo da Constante do Diferencial (Grade da Hélice):
        </h2>
        <div className="div_inputs">
          <div className="div_input_span_modal">
            <Input
              label={"Diâmetro da Engrenagem:"}
              value={diameter}
              placeholder={"Diâmetro Externo"}
              onChange={(e) => setDiameter(e.target.value)}
            />
          </div>
          <div className="div_input_span_modal">
            <Input
              label={"Número de dentes(Eng.):"}
              value={teeth}
              placeholder={"Engrenagem (Z)"}
              onChange={(e) => setTeeth(e.target.value)}
            />
          </div>
          <div className="div_input_span_modal">
            <Input
              label={"Ângulo da Hélice:"}
              value={angle}
              placeholder={"Grau decimal"}
              onChange={(e) => setAngle(e.target.value)}
            />
          </div>
          <div className="div_input_span_modal">
            <Input
              label={"Relação Diferencial:"}
              value={relation}
              placeholder={"Relação"}
              onChange={(e) => setRelation(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-red-500 text-white py-2 px-4 rounded-md mr-2"
            onClick={() => {
              cleanInputs();
              onClose();
            }}
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded-md"
            onClick={() => {
              postValuesConstant();
              onClose();
            }}
          >
            Calcular
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
