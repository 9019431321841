const printPDF = () => {
    const a = window.open("", "PRINT", "height=3508,width=2480");
    let s = "";

    document.querySelectorAll("link,style").forEach((e) => {
      s += e.outerHTML;
    });

    a.document.write(
      `
            <html>
                <head>
                    ${s}
                </head>
                <body>
                    ${document.querySelector(".print_pdf").innerHTML}
                </body>
            </html>
        `
    );

    a.document.close();
    a.focus();
    a.print();
};

export { printPDF }