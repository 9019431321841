import React from "react";
import "./Loading.css";
import enggif from "./eng1.gif";

const Loading = () => {
  return (
    <>
      <div className="background_black">
        <div className="loading">
          <img src={enggif} alt="Carregando" />
        </div>
      </div>
    </>
  );
};

export default Loading;
