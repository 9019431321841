import React from "react";
import "./ResponseDaDAPI.css";
import { printPDF } from "../../utils/printer";

const ResponseDaDAPI = ({
  valuesAPI,
  setMostraModal,
  diameter,
  numberToothGrinding,
  fuscoAdvance,
  fixedReduction,
  gearType,
}) => {
  const heightTooth = valuesAPI.data?.apparent_module * 2.1666;

  return (
    <>
      <div className='removeFilters_background'>
        <div className='background_center_removeFilters'>
          <div className='div_all_itens_api'>
            <div className='flex flex-row justify-center items-center gap-4'>
              <button
                onClick={() => setMostraModal(false)}
                className='block w-40 bg-red-600 py-2 rounded-2xl text-white font-semibold shadow-md hover:bg-red-400 transition duration-300'
              >
                VOLTAR
              </button>
              <button
                onClick={printPDF}
                className='block w-40 bg-green-500 py-2 rounded-2xl text-white font-semibold shadow-md hover:bg-green-400 transition duration-300'
              >
                IMPRIMIR PDF
              </button>
            </div>

            <h2 className='mt-5 text-center text-2xl font-bold leading-4 tracking-tight text-gray-900'>
              Valores:
            </h2>
            <div className='print_pdf'>
              <div className='values-api_response'>
                <div className='container_engrenagens table-wrapper'>
                  <table>
                    <thead>
                      <tr>
                        <th>DESCRIÇÃO</th>
                        <th>VALOR</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gearType === "Dente Reto" ? (
                        <></>
                      ) : (
                        <tr>
                          <td>ALFA:</td>
                          <td>{valuesAPI.data?.angle.toFixed(2)}°</td>
                        </tr>
                      )}
                      <tr>
                        <td>ENGRENAGEM {gearType.toUpperCase()}:</td>
                        <td>
                          {gearType === "Direita"
                            ? "NÚMEROS ÍMPARES DE EIXOS: 3 OU 5"
                            : gearType === "Esquerda"
                            ? "NÚMEROS PARES DE EIXOS: 2 OU 4"
                            : "RETA DE ANGULO 0°"}
                        </td>
                      </tr>
                      <tr>
                        <td>PASSO DA HÉLICE:</td>
                        <td>
                          {valuesAPI.data?.gear_helix_pitch.toFixed(2)} MM
                        </td>
                      </tr>
                      <tr>
                        <td>NÚMERO DE DENTES A FREZAR:</td>
                        <td>{numberToothGrinding}</td>
                      </tr>
                      <tr>
                        <td>NÚMERO DE DENTES DA COROA DO DIVISOR:</td>
                        <td>{fuscoAdvance}</td>
                      </tr>
                      <tr>
                        <td>RELAÇÃO:</td>
                        <td>{valuesAPI.data?.relation.toFixed(5)}</td>
                      </tr>
                      <tr>
                        <td>MÓDULO APARENTE:</td>
                        <td>{valuesAPI.data?.apparent_module.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>MÓDULO NORMAL:</td>
                        <td>{valuesAPI.data?.normal_module.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>Nº DE FRESA:</td>
                        <td>
                          {numberToothGrinding <= 13
                            ? 1
                            : numberToothGrinding >= 14 &&
                              numberToothGrinding <= 16
                            ? 2
                            : numberToothGrinding >= 17 &&
                              numberToothGrinding <= 20
                            ? 3
                            : numberToothGrinding >= 21 &&
                              numberToothGrinding <= 25
                            ? 4
                            : numberToothGrinding >= 26 &&
                              numberToothGrinding <= 34
                            ? 5
                            : numberToothGrinding >= 35 &&
                              numberToothGrinding <= 54
                            ? 6
                            : numberToothGrinding >= 55 &&
                              numberToothGrinding <= 134
                            ? 7
                            : 8}
                        </td>
                      </tr>
                      <tr>
                        <td>DIÂMETRO EXTERNO:</td>
                        <td>{diameter} MM</td>
                      </tr>
                      <tr>
                        <td>DIÂMETRO PRIMITIVO:</td>
                        <td>
                          {valuesAPI.data?.primitive_diameter.toFixed(2)} MM
                        </td>
                      </tr>
                      <tr>
                        <td>DIÂMETRO INTERNO:</td>
                        <td>
                          {valuesAPI.data?.internal_diameter < 0 ? (
                            <p>
                              NÃO EXISTE ENGRENAGEM QUE ATENDA A ESSA CONDIÇÃO!
                              AUMENTAR O DIÂMETRO EXTERNO
                            </p>
                          ) : (
                            <p>
                              {valuesAPI.data?.internal_diameter.toFixed(2)}
                            </p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>ALTURA DO DENTE:</td>
                        <td>{heightTooth.toFixed(2)} MM</td>
                      </tr>
                      <tr>
                        <td>PASSO DO FUSO:</td>
                        <td>{fixedReduction}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h2 className='mt-5 text-center text-2xl font-bold leading-4 tracking-tight text-gray-900'>
                  Relação de discos encontrados:
                </h2>
                <div className='values-api_response'>
                  <div className='container_engrenagens table-wrapper'>
                    <table>
                      <thead>
                        <tr>
                          <th>DISCO:</th>
                          <th>ROTAÇÕES:</th>
                          <th>FUROS A PERCORRER:</th>
                        </tr>
                      </thead>
                      <tbody>
                        {valuesAPI.data?.disc &&
                          valuesAPI.data.disc.map((e, index) => (
                            <tr key={index}>
                              <td>{e.disc}</td>
                              <td>{e.rotations}</td>
                              <td>{e.hole}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {gearType !== "Dente Reto" && (
                  <h2 className='mt-5 text-center text-2xl font-bold leading-4 tracking-tight text-gray-900'>
                    Grade do Divisor:
                  </h2>
                )}
                {valuesAPI.data?.engines.length === 0 && (
                  <>
                    <div className='values-api_response'>
                      <div className='container_engrenagens table-wrapper'>
                        NÃO EXISTEM CONDIÇÕES DE MONTAGEM QUE ATENDAM À PRECISÃO
                        E AO NÚMERO MÁXIMO E MÍNIMO DE DENTES DEFINIDOS.
                        <br style={{ marginBottom: "10px" }} />
                        SUGESTÃO: DIMINUA A PRECISÃO E/OU AUMENTE O NÚMERO
                        MÁXIMO DE DENTES DAS ENGRENAGENS.
                      </div>
                    </div>
                  </>
                )}

                {gearType !== "Dente Reto" &&
                  valuesAPI.data?.engines.length > 0 && (
                    <div className='values-api_response'>
                      <div className='container_engrenagens table-wrapper'>
                        <table>
                          <thead>
                            <tr>
                              <th>ENG(A):</th>
                              <th>ENG(B):</th>
                              {valuesAPI.data.engines[0].c !== 0 ? (
                                <>
                                  <th>ENG(C):</th>
                                  <th>ENG(D):</th>
                                </>
                              ) : (
                                <></>
                              )}

                              {valuesAPI.data.engines[0].e !== 0 ? (
                                <>
                                  <th>ENG(E):</th>
                                  <th>ENG(F):</th>
                                </>
                              ) : (
                                <></>
                              )}

                              <th>RELAÇÃO:</th>
                              <th>ÂNGULO HÉLICE (ALFA):</th>
                              <th>MONTAGEM:</th>
                            </tr>
                          </thead>
                          <tbody>
                            {valuesAPI.data?.engines &&
                              valuesAPI.data.engines.map((element, index) => (
                                <tr key={index}>
                                  <td>{element.a}</td>
                                  <td>{element.b}</td>
                                  {element.c === 0 ? (
                                    <></>
                                  ) : (
                                    <td>{element.c}</td>
                                  )}
                                  {element.d === 0 ? (
                                    <></>
                                  ) : (
                                    <td>{element.d}</td>
                                  )}
                                  {element.e === 0 ? (
                                    <></>
                                  ) : (
                                    <td>{element.e}</td>
                                  )}
                                  {element.f === 0 ? (
                                    <></>
                                  ) : (
                                    <td>{element.f}</td>
                                  )}
                                  <td>{element.relation.toFixed(5)}</td>
                                  <td>{element.helix.toFixed(2)}</td>
                                  <td>
                                    <a
                                      href={`${process.env.REACT_APP_BASE_URL}${element.link}`}
                                      target='_blank'
                                      rel='noreferrer'
                                      className='text-gray-600 hover:text-gray-800 font-light'
                                    >
                                      Ver imagem
                                    </a>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            <button
              onClick={() => setMostraModal(false)}
              className="className='block w-80 bg-red-600 mt-4 py-2 rounded-2xl text-white font-semibold mb-2'"
            >
              VOLTAR
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResponseDaDAPI;
