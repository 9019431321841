import React from "react";
import InputMask from "react-input-mask";

const Input = ({ label, value, onChange, placeholder, disabled, mask }) => {
  return (
    <>
      <label className="block text-gray-700 text-sm font-bold ">{label}</label>
      <InputMask
        mask={mask}
        type="text"
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        className="py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
      />
    </>
  );
};

export default Input;
