import React from "react";

const Input = ({ label, value, onChange, placeholder, disabled }) => {
  return (
    <>
      <label className='block text-gray-700 text-sm font-bold '>{label}</label>
      <input
        type='number'
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        min={0}
        onWheel={(e) => e.target.blur()}
        className='py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full'
      />
    </>
  );
};

export default Input;
