import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,

  interceptors: {
    response: (response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      const error = new Error(`HTTP Error ${response.statusText}`);
      error.status = response.statusText;
      error.response = response;

      localStorage.clear()
      window.location.href = '/login';

      throw error;
    },
  },
});

export default api;
