import React from "react";
import Input from "../Input/Input";

const ModalGridAdvanced = ({
  isOpen,
  onClose,
  setGridReducion,
  gridReduction,
  fixedReduction,
  setGridAdvance,
}) => {
  const defaultvValues = [
    { material: "AÇO 1045", gridAdvance: 0.1, fixedReduction: 6 },
    { material: "AÇO 1020", gridAdvance: 0.2, fixedReduction: 6 },
    { material: "NYLON", gridAdvance: 0.5, fixedReduction: 6 },
  ];
  const cleanInputs = () => {
    setGridReducion("");
    setGridAdvance("");
  };

  const calcGridAdvanced = () => {
    let result = fixedReduction * (1 / gridReduction);
    setGridAdvance(result);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-container fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className=" bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
        <h2 className="text-lg mb-4">Cálculo do Avanço do Fuso:</h2>
        <div className="div_inputs">
          <div className="div_input_span grid_reduction_before relative">
            <Input
              label={"Redução da grade do fuso:*"}
              value={gridReduction}
              placeholder={"Relação de redução"}
              onChange={(e) => setGridReducion(e.target.value)}
            />
          </div>
        </div>
        <h2 className="text-lg mb-4">Sugestões:</h2>

        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>MATERIAL:</th>
                <th>AVANÇO DO FUSO</th>
              </tr>
            </thead>
            <tbody>
              {defaultvValues.map((e) => (
                <tr key={e.material}>
                  <td>{e.material}</td>
                  <td>{e.gridAdvance} MM/V</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-3">
          <button
            type="button"
            className="bg-red-500 text-white py-2 px-4 rounded-md mr-2"
            onClick={() => {
              cleanInputs();
              onClose();
            }}
          >
            Cancelar
          </button>
          <button
            type="submit"
            disabled={fixedReduction === "" || gridReduction === ""}
            className={`w-50 py-2 px-4 rounded-lg text-white ml-4 ${
              fixedReduction === "" || gridReduction === ""
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-green-500 hover:bg-green-600 focus:outline-none focus:bg-green-600"
            }`}
            onClick={() => {
              calcGridAdvanced();
              onClose();
            }}
          >
            Calcular
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalGridAdvanced;
