import React, { useState } from "react";
import InputText from "../InputText/InputText";
import showPasswordIcon from "../../assets/showIconGrey.svg";
import hidePasswordIcon from "../../assets/hideIconBlack.svg";
import api from "../../axios/config";
import Loading from "../Loading/Loading";

const CreatedUser = ({ isOpen, onClose, onConfirm }) => {
  const tokenAuthenticated = sessionStorage.getItem("token");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [objectUser, setObjectUser] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    cpf_cnpj: "",
  });

  const postAPICreateUser = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        "user/add",
        {
          name: objectUser.name,
          email: objectUser.email,
          password: objectUser.password,
          phone: objectUser.phone,
          cpf_cnpj: objectUser.cpf_cnpj,
        },
        {
          headers: {
            token: tokenAuthenticated,
          },
        }
      );
      const data = response.data;

      setObjectUser({
        name: "",
        email: "",
        password: "",
        phone: "",
        cpf_cnpj: "",
      });
      setErrorPassword(false);
      onConfirm();
    } catch (error) {
      if (error.response?.status === 400) {
        setErrorPassword(true);
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-6 rounded-lg shadow-lg z-10 max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">Adicionar usuário:</h2>
        {errorPassword && (
          <div className="text-red-500 text-center mb-4 border-2 border-red-500 bg-red-100 p-2 rounded">
            A senha deve ter 8+ caracteres, com letra maiúscula, minúscula,
            número e símbolo (!$%&*@?_).
          </div>
        )}

        <div className="flex flex-col justify-start items-start mt-1 mb-3  gap-1">
          <InputText
            label={"Nome do usuario:*"}
            value={objectUser.name}
            onChange={(e) =>
              setObjectUser({ ...objectUser, name: e.target.value })
            }
            placeholder={"Nome"}
          />
          <InputText
            label={"Email do usuario:*"}
            value={objectUser.email}
            onChange={(e) =>
              setObjectUser({ ...objectUser, email: e.target.value })
            }
            placeholder={"Email"}
          />
          {objectUser.password &&
            objectUser.confirmPassword &&
            objectUser.password !== objectUser.confirmPassword && (
              <p className="text-red-500 text-sm mt-2">
                As senhas não são iguais.
              </p>
            )}
          <div className="relative w-full">
            <label className="block text-gray-700 text-sm font-bold ">
              Senha do usuario:*
            </label>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Confirme a senha"
              className={`py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full`}
              value={objectUser.password}
              onChange={(e) =>
                setObjectUser({
                  ...objectUser,
                  password: e.target.value,
                })
              }
            />
            <img
              src={showPassword ? showPasswordIcon : hidePasswordIcon}
              alt="Show/Hide Password"
              title={showPassword ? "Esconder Senha" : "Mostrar Senha"}
              onClick={() => setShowPassword(!showPassword)}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "10px",
                top: "70%",
                transform: "translateY(-50%)",
                zIndex: 1,
              }}
            />
          </div>
          <div className="relative w-full">
            <label className="block text-gray-700 text-sm font-bold ">
              Confirme a senha:*
            </label>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Confirme a senha"
              className={`py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full`}
              value={objectUser.confirmPassword}
              onChange={(e) =>
                setObjectUser({
                  ...objectUser,
                  confirmPassword: e.target.value,
                })
              }
              onPaste={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
            />
            <img
              src={showPassword ? showPasswordIcon : hidePasswordIcon}
              alt="Show/Hide Password"
              title={showPassword ? "Esconder Senha" : "Mostrar Senha"}
              onClick={() => setShowPassword(!showPassword)}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "10px",
                top: "70%",
                transform: "translateY(-50%)",
                zIndex: 1,
              }}
            />
          </div>
          <InputText
            label={"Telefone do usuario:*"}
            mask="(99) 99999-9999"
            value={objectUser.phone}
            onChange={(e) =>
              setObjectUser({ ...objectUser, phone: e.target.value })
            }
            placeholder={"Telefone"}
          />
          <InputText
            label={"CPF / CNPJ do usuario:"}
            value={objectUser.cpf_cnpj}
            onChange={(e) =>
              setObjectUser({ ...objectUser, cpf_cnpj: e.target.value })
            }
            placeholder={"CPF / CNPJ"}
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            className="w-50 bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 focus:outline-none focus:bg-red-600 ml-4"
            onClick={() => {
              setObjectUser({
                name: "",
                email: "",
                password: "",
                phone: "",
                cpf_cnpj: "",
              });
              setErrorPassword(false);
              onClose();
            }}
          >
            Cancelar
          </button>
          {loading && <Loading />}
          {!loading && (
            <button
              disabled={
                objectUser.name === "" ||
                objectUser.email === "" ||
                objectUser.password === "" ||
                objectUser.confirmPassword === "" ||
                objectUser.password !== objectUser.confirmPassword ||
                objectUser.phone === ""
              }
              className={`w-50 py-2 px-4 rounded-lg text-white ml-4 ${
                objectUser.name === "" ||
                objectUser.email === "" ||
                objectUser.password === "" ||
                objectUser.confirmPassword === "" ||
                objectUser.password !== objectUser.confirmPassword ||
                objectUser.phone === ""
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-green-500 hover:bg-green-600 focus:outline-none focus:bg-green-600"
              }`}
              onClick={() => {
                postAPICreateUser();
              }}
            >
              Criar Usuario
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreatedUser;
