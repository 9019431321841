import React, { useState } from "react";
import api from "../../axios/config";
import Loading from "../Loading/Loading";

const DeleteModal = ({ isOpen, onClose, onConfirm, idUser }) => {
  const tokenAuthenticated = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const deleteUser = async () => {
    setLoading(true);
    try {
      const response = await api.delete(`user/delete/${idUser}`, {
        headers: {
          token: tokenAuthenticated,
        },
      });
      const data = response.data;
    } catch (error) {
      console.log("ERRO", error);
    } finally {
      setLoading(false);
      onConfirm();
    }
  };

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-6 rounded-lg shadow-lg z-10 max-w-sm w-full">
        <h2 className="text-xl font-bold mb-4">
          Tem certeza que deseja excluir?
        </h2>
        <div className="flex justify-end space-x-4">
          <button
            className="w-50 bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 focus:outline-none focus:bg-red-600 ml-4"
            onClick={onClose}
          >
            Cancelar
          </button>
          {loading && <Loading />}
          {!loading && (
            <button
              className="w-50 bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 focus:outline-none focus:bg-green-600 ml-4"
              onClick={() => {
                deleteUser();
              }}
            >
              Sim
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
