import React, { useState } from "react";
import "./PayloadDaD.css";
import api from "../../axios/config";
import ResponseDaDAPI from "../ResponseDaDAPI/ResponseDaDAPI";
import Loading from "../Loading/Loading";
import Header from "../Header/Header";
import Input from "../Input/Input";

const PayloadDaD = () => {
  const tokenAuthenticated = sessionStorage.getItem("token");
  const [numberCogs, setNumberCogs] = useState(4);
  const [pitchOrHelixAngle, setPitchOrHelixAngle] = useState("");
  const [diameter, setDiameter] = useState("");
  const [numberToothGrinding, setNumberToothGrinding] = useState("");
  const [fuscoAdvance, setFuscoAdvance] = useState("");
  const [fixedReduction, setFixedReduction] = useState("");
  const [minTeeth, setMinTeeth] = useState(20);
  const [maxTeeth, setMaxTeeth] = useState(100);
  const [numberSample, setNumberSample] = useState(100);
  const [precision, setPrecision] = useState(5);
  const [gearType, setGearType] = useState("Direita");
  const [angleOrStep, setAngleOrStep] = useState("Ângulo");
  const [mostraModal, setMostraModal] = useState(false);
  const [valuesAPI, setValuesAPI] = useState([]);
  const [loading, setLoading] = useState(false);

  const postApiCalculatedDaD = async () => {
    const payload = {
      teeth: Number(numberToothGrinding),
      crown_teeth: Number(fuscoAdvance),
      spindle_pitch: Number(fixedReduction),
      num_engines: Number(numberCogs),
      diameter: Number(diameter),
      min_cog: Number(minTeeth),
      max_cog: Number(maxTeeth),
      precision: Number(precision),
      samples: Number(numberSample),
      gear_type: gearType,
    };
    if (angleOrStep === "Ângulo") {
      payload.angle = gearType === "Dente Reto" ? 0 : Number(pitchOrHelixAngle);
    } else {
      payload.step = gearType === "Dente Reto" ? 0 : Number(pitchOrHelixAngle);
    }

    setLoading(true);
    setValuesAPI([]);
    await api
      .post("engine/calc_dad", payload, {
        headers: {
          token: tokenAuthenticated,
        },
      })
      .then((response) => {
        const data = response.data;
        setValuesAPI(data);
      })
      .catch((error) => {
        console.error("ERRO ->", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Header />
      <h1 style={{ textAlign: "center", marginTop: "10px", fontSize: "30px" }}>
        Cálculo: <strong>Dente a Dente</strong>
      </h1>
      <div className="container-all-p">
        <div className="mt-2 mb-2">
          <div className="flex flex-row">
            <label className="block mr-3">Número de engrenagens:</label>
            <label className="inline-flex items-center mr-4">
              <input
                type="radio"
                value="2"
                checked={numberCogs === 2}
                onChange={(event) =>
                  setNumberCogs(parseInt(event.target.value))
                }
                className="form-radio text-indigo-600 h-5 w-5 cursor-pointer"
              />
              <span className="ml-2 font-bold">2</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="4"
                checked={numberCogs === 4}
                onChange={(event) =>
                  setNumberCogs(parseInt(event.target.value))
                }
                className="form-radio text-indigo-600 h-5 w-5 cursor-pointer"
              />
              <span className="ml-2 font-bold">4</span>
            </label>
          </div>
        </div>
        <div className="mt-2 mb-2"></div>

        <div className="div_inputs">
          <div className="div_input_span">
            <label className="block text-gray-700 text-sm font-bold">
              Sentido de Helice da Engrenagem:*
            </label>
            <select
              value={gearType}
              onChange={(e) => setGearType(e.target.value)}
              className="py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full h-10"
            >
              <option value="Direita">Direita</option>
              <option value="Esquerda">Esquerda</option>
              <option value="Dente Reto">Dente Reto</option>
            </select>
          </div>
          {gearType === "Dente Reto" ? (
            <></>
          ) : (
            <div className="div_input_span">
              <label className="block text-gray-700 text-sm font-bold">
                Ângulo / Passo da Hélice(Eng):*
              </label>
              <select
                value={angleOrStep}
                onChange={(e) => setAngleOrStep(e.target.value)}
                className="py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full h-10"
              >
                <option value="Ângulo">Ângulo</option>
                <option value="Passo">Passo</option>
              </select>

              <input
                type="number"
                value={pitchOrHelixAngle}
                placeholder={`${angleOrStep} de Hélice(Eng)`}
                onChange={(e) => setPitchOrHelixAngle(e.target.value)}
                onWheel={(e) => e.target.blur()}
                className="py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
              />
            </div>
          )}

          <div className="div_input_span">
            <Input
              label={"Diâmetro da Engrenagem:*"}
              value={diameter}
              placeholder={"Diâmetro Externo"}
              onChange={(e) => setDiameter(e.target.value)}
            />
          </div>
          <div className="div_input_span">
            <Input
              label={"Número de dentes(Eng.):*"}
              value={numberToothGrinding}
              placeholder={"Engrenagem (Z)"}
              onChange={(e) => setNumberToothGrinding(e.target.value)}
            />
          </div>
          <div className="div_input_span">
            <Input
              label={"Passo do Fuso(Fres.):*"}
              value={fixedReduction}
              placeholder={"Passo Fuso Fresadora"}
              onChange={(e) => setFixedReduction(e.target.value)}
            />
          </div>
          <div className="div_input_span">
            <Input
              label={"Número de dentes(Cor. Div):*"}
              value={fuscoAdvance}
              placeholder={"(Z) Coroa Divisor"}
              onChange={(e) => setFuscoAdvance(e.target.value)}
            />
          </div>
          <div className="div_input_span">
            <Input
              label={"Número de Dentes minimos:*"}
              value={minTeeth}
              placeholder={"Número de Dentes minimos"}
              onChange={(e) => setMinTeeth(e.target.value)}
            />
          </div>
          <div className="div_input_span">
            <Input
              label={"Número de Dentes maximos:*"}
              value={maxTeeth}
              placeholder={"Número de Dentes maximos"}
              onChange={(e) => setMaxTeeth(e.target.value)}
            />
          </div>
          <div className="div_input_span">
            <Input
              label={"Número de exemplos:*"}
              value={numberSample}
              placeholder={"Número de exemplos"}
              onChange={(e) => setNumberSample(e.target.value)}
            />
          </div>
          <div className="div_input_span">
            <Input
              label={" Precisão:*"}
              value={precision}
              placeholder={"Precisão"}
              onChange={(e) => setPrecision(e.target.value)}
            />
          </div>
        </div>

        <div className="my-2 w-1/2 lg:w-1/4">
          <button
            className={`block w-full mt-4 py-2 rounded-2xl font-semibold mb-2 ${
              (gearType !== "Dente Reto" && pitchOrHelixAngle === "") ||
              diameter === "" ||
              numberToothGrinding === "" ||
              fuscoAdvance === "" ||
              fixedReduction === "" ||
              minTeeth === "" ||
              maxTeeth === "" ||
              numberSample === "" ||
              precision === ""
                ? "bg-gray-400 text-white  cursor-not-allowed"
                : "bg-blue-600 text-white"
            }`}
            onClick={() => {
              postApiCalculatedDaD();
              setMostraModal(true);
            }}
            disabled={
              (gearType !== "Dente Reto" && pitchOrHelixAngle === "") ||
              diameter === "" ||
              numberToothGrinding === "" ||
              fuscoAdvance === "" ||
              fixedReduction === "" ||
              minTeeth === "" ||
              maxTeeth === "" ||
              numberSample === "" ||
              precision === ""
            }
          >
            Calcular
          </button>

          {loading && <Loading />}
          {!loading && mostraModal && (
            <ResponseDaDAPI
              valuesAPI={valuesAPI}
              setMostraModal={setMostraModal}
              diameter={diameter}
              pitchOrHelixAngle={pitchOrHelixAngle}
              numberToothGrinding={numberToothGrinding}
              fuscoAdvance={fuscoAdvance}
              fixedReduction={fixedReduction}
              numberCogs={numberCogs}
              gearType={gearType}
              angleOrStep={angleOrStep}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PayloadDaD;
